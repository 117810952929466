export const environment = {
    production: false,
    rootRoute: 'tssea',
    APIEndpoint: 'https://api.dev.clientinformation.mto.gov.on.ca',
    disableRouteGuard: false,
    sessionWarningConfig: {
        idle: 300, // in seconds, how long the user has to be idle for before receiving a session expiry warning
        timeout: 20, // in seconds, how long the user has to either extend the session or end the session
        ping: 5 // in seconds, a value that can be used to 'ping' the user, we don't use it but still needs to be defined here
    },
    disableSessionWarning: false,
    googleAnalyticsID: 'G-LGTDR979TE'
};
